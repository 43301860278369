import React from 'react'
import AddProfile from '../home/ServicesByINO/AddProject/AddProfile/AddProfile'

export default function Profile() {
  return (
    <div  className='bg-layout shadow-md drop-shadow-md lg:w-[155vh]  mb-[7rem]  lg:ml-[268px] relative top-[1rem] fade-in-left '>
      <AddProfile/>
    </div>
  )
}
