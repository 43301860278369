import React from 'react'
import { useNavigate } from "react-router-dom";



export default function AllReceived() {
  const navigate = useNavigate();

  const handlelistener = () => {
    navigate(`/findproducts`);
  };
  return (
    <>
    <div className="bg-layout drop-shadow-md lg:w-[152vh] mb-12 lg:ml-[17rem] relative top-[1rem] swing-in-top-fwd">

<h1 className="font-all font-semibold text-center text-[24px] mt-4 mb-12">
Bussiness card Received
</h1>

<div className="--content--inquiries">

<div className=" lg:w-[85%] lg:mx-auto flex flex-col lg:gap-[4px]">
{/* --1  */}
<div className="flex flex-row gap-[45px] lg: items-center border-y border-gray-200 py-[6px]">
<div className="--img">
  <img className='w-auto rounded-full h-[55px]' src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg&ga=GA1.2.2054915257.1675252966&semt=sph" alt="" /> 
</div>
<div className="-- flex  flex-col gap-[4px]">
  <span className='font-semibold inline'>jhon sord</span>
  <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
</div>
<div className="flex flex-row items-center lg:gap-[32px]">

<span className='font-all'>1 hr</span>
<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View full message  </button>
</div>
</div>
{/* --2  */}
<div className="flex flex-row gap-[45px] lg: items-center border-y border-gray-200  py-[6px]">
<div className="--img">
  <img className='w-auto rounded-full h-[55px]' src="https://img.freepik.com/free-psd/girl-avatar-emoji-3d-icon_23-2150579870.jpg?w=740&t=st=1688299634~exp=1688300234~hmac=36def7c2f38bdea6368698acddbee74b9ba0aff155d9b69a6256b1f4b700d685" alt="" /> 
</div>
<div className="-- flex  flex-col gap-[4px]">
  <span className='font-semibold inline'>jhon sord</span>
  <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
</div>
<div className="flex flex-row items-center lg:gap-[32px]">

<span className='font-all'>1 hr</span>
<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View full message  </button>
</div>
</div>
{/* --3  */}
<div className="flex flex-row gap-[45px] lg: items-center border-y border-gray-200  py-[6px]">
<div className="--img">
  <img className='w-auto rounded-full h-[55px]' src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=626&ext=jpg&ga=GA1.1.2054915257.1675252966&semt=sph" alt="" /> 
</div>
<div className="-- flex  flex-col gap-[4px]">
  <span className='font-semibold inline'>jhon sord</span>
  <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
</div>
<div className="flex flex-row items-center lg:gap-[32px]">

<span className='font-all'>1 hr</span>
<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View full message  </button>
</div>
</div>
{/* --1  */}
<div className="flex flex-row gap-[45px] lg: items-center border-y border-gray-200 py-[6px]">
<div className="--img">
  <img className='w-auto rounded-full h-[55px]' src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=626&ext=jpg&ga=GA1.2.2054915257.1675252966&semt=sph" alt="" /> 
</div>
<div className="-- flex  flex-col gap-[4px]">
  <span className='font-semibold inline'>jhon sord</span>
  <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
</div>
<div className="flex flex-row items-center lg:gap-[32px]">

<span className='font-all'>1 hr</span>
<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View full message  </button>
</div>
</div>
{/* --2  */}
<div className="flex flex-row gap-[45px] lg: items-center border-y border-gray-200  py-[6px]">
<div className="--img">
  <img className='w-auto rounded-full h-[55px]' src="https://img.freepik.com/free-psd/girl-avatar-emoji-3d-icon_23-2150579870.jpg?w=740&t=st=1688299634~exp=1688300234~hmac=36def7c2f38bdea6368698acddbee74b9ba0aff155d9b69a6256b1f4b700d685" alt="" /> 
</div>
<div className="-- flex  flex-col gap-[4px]">
  <span className='font-semibold inline'>jhon sord</span>
  <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
</div>
<div className="flex flex-row items-center lg:gap-[32px]">

<span className='font-all'>1 hr</span>
<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View full message  </button>
</div>
</div>
{/* --3  */}
<div className="flex flex-row gap-[45px] lg: items-center border-y border-gray-200  py-[6px]">
<div className="--img">
  <img className='w-auto rounded-full h-[55px]' src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?size=626&ext=jpg&ga=GA1.1.2054915257.1675252966&semt=sph" alt="" /> 
</div>
<div className="-- flex  flex-col gap-[4px]">
  <span className='font-semibold inline'>jhon sord</span>
  <span className='inline font-all'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga, molestias.</span>
</div>
<div className="flex flex-row items-center lg:gap-[32px]">

<span className='font-all'>1 hr</span>
<button class="bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  hover:text-white flex flex-row items-center justify-center gap-2">View full message  </button>
</div>
</div>

</div>


<div className="button--action--reverse flex justify-center ">



<button onClick={handlelistener} class="my-[2rem] bg-[#F8F8F8] shadow-lg p-[3px] w-[12rem] rounded-full transform hover:scale-105 duration-500 ease-in-out hover:bg-[#CB2229]  border-[1px] border-[#CB2229] hover:text-white flex flex-row items-center justify-center gap-2">Go Back  </button>
</div>

</div>


</div>
  </>
  )
}
